<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container
    grid-list-sm
    class="mt-4"
  >
    <section id="knowledge-base">
      <template v-if="!isLoading">
        <!-- knowledge-base-bg  -->
        <v-card
          flat
          class="d-flex align-center justify-center text-center mb-7"
          :style="`background-image: url(${imgBackground}) !important; min-height: 320px;background-position: center center;background-size: 100%;background-repeat: no-repeat;`"
        >
          <v-card-text>
            <p class="kb-title text-2xl font-weight-semibold primary--text mb-2">
              <span v-if="event.banner_include_title">
                {{ getTitle(event) }}
              </span>
            </p>
            <p
              class="mb-7 primary--text"
            >
              <span v-if="event.banner_include_description">
                {{ getDescripcion(event) }}
              </span>
            </p>
          </v-card-text>
        </v-card>

        <!-- kb search content -->
        <div id="knowledge-base-content">
          <v-row class="kb-search-content-info match-height">
            <v-col
              v-if="!event.banner_include_description"
              cols="12"
              md="12"
            >
              <p
                class="primary--text text-left"
              >
                <span>
                  {{ getDescripcion(event) }}
                </span>
              </p>
            </v-col>

            <v-col
              md="8"
              sm="12"
              cols="12"
              class="kb-search-content"
            >
              <v-card
                color="text-center"
              >
                <v-card-text>
                  <v-form
                    class="pb-6 pt-2"
                  >
                    <v-row v-if="!event.vencido">
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <h3 class="ml-5">
                          {{ $t('dashboard.solicitaCita') }}
                        </h3>
                      </v-col>
                    </v-row>
                    <template v-if="messageSend">
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <p
                            class="mb-7 primary--text"
                          >
                            {{ $t('landing.event.h1') }}
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-img
                            height="150px"
                            contain
                            :src="imgForm"
                          ></v-img>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <p
                            class="mb-7 primary--text"
                          >
                            {{ $t('landing.event.h4') }}
                          </p>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-if="!messageSend && !event.vencido">
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="model.name"
                            :label="`${$t('landing.eventSoli.fullname')} *`"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="textRequiered"
                            :placeholder="$t('landing.eventSoli.names')"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="model.email"
                            label="Email *"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="emailRules"
                            placeholder="Email"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="model.company"
                            :label="`${$t('landing.eventSoli.company')} *`"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="textRequiered"
                            :placeholder="$t('landing.eventSoli.yourCompany')"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="model.rol"
                            :label="`${$t('landing.eventSoli.rol')} *`"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="textRequiered"
                            :placeholder="$t('landing.eventSoli.yourRol')"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="model.date"
                            :items="datesFree"
                            :label="`${$t('landing.eventSoli.date')} *`"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="textRequiered"
                            @change="setDate"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  {{ $t('landing.eventSoli.notTimes') }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        <!--<v-menu
                          v-model="menuFrom"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedFromDateFormatted"
                              :label="`${$t('landing.eventSoli.date')} *`"
                              persistent-hint
                              readonly
                              v-bind="attrs"
                              outlined
                              dense
                              style="border-radius: 5px 0px 0px 5px"
                              :rules="textRequiered"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="model.date"
                            no-title
                            :min="$moment(event.dateFrom).toISOString().substr(0, 10)"
                            :max="$moment(event.dateTo).toISOString().substr(0, 10)"
                            locale="es"
                            :show-current="false"
                            @input="menuFrom = false"
                          ></v-date-picker>
                        </v-menu>-->
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="model.time"
                            :items="times"
                            :label="`${$t('landing.eventSoli.time')} *`"
                            outlined
                            dense
                            hide-details="auto"
                            :disabled="!model.date"
                            :rules="textRequiered"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  {{ $t('landing.eventSoli.notTimes') }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="model.site_web"
                            :label="$t('landing.eventSoli.sitioWeb')"
                            outlined
                            dense
                            hide-details="auto"

                            :placeholder="$t('landing.eventSoli.subSitioWeb')"
                            clearable
                          ></v-text-field>
                          <!--
                              @input="autocompletarWeb"
                          :rules="urlRules"
                          -->
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="model.code_country"
                            :label="`${$t('register.codePais')}*`"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="textRequiered"
                            :placeholder="$t('register.codePais')"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="model.phone"
                            :label="`${$t('register.noTelefo')} *`"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="phoneRules"
                            :placeholder="$t('register.noTelefo')"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-textarea
                            v-model="model.comentarios"
                            :label="`${$t('landing.eventSoli.comentarios')}`"
                            :placeholder="`${$t('landing.eventSoli.subComentarios')}`"
                            outlined
                            dense
                            rows="2"
                            hide-details="auto"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <!--<v-btn
                            color="primary"
                            outlined
                            block
                            :loading="loading"
                            @click="onSubmit"
                          >
                            {{ $t('btn.send') }}
                          </v-btn>
                          <vue-recaptcha
                            v-if="sitekey"
                            ref="recaptcha"
                            :sitekey="sitekey"
                            :load-recaptcha-script="true"
                            @verify="onVerify"
                            @expired="onExpired"
                          /> -->
                          <vue-recaptcha
                            v-if="sitekey"
                            ref="recaptcha"
                            :sitekey="sitekey"
                            :load-recaptcha-script="true"
                            @verify="onVerify"
                            @expired="onExpired"
                          >
                            <v-btn
                              color="primary"
                              outlined
                              block
                              :loading="loading"
                              @click="onVerify"
                            >
                              {{ $t('btn.send') }}
                            </v-btn>
                          </vue-recaptcha>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-if="event.vencido">
                      <EventPageImgs
                        v-if="event.imgs.length > 0 || event.socios.length > 0"
                        :event="event"
                      />
                    </template>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              md="4"
              sm="12"
              cols="12"
              class="kb-search-content"
            >
              <v-card
                color="text-center"
              >
                <v-card-text class="justify-center">
                  <v-img
                    height="100px"
                    contain
                    :src="img"
                  ></v-img>
                </v-card-text>
                <v-card-text>
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-text>
                  <div
                    v-if="event.country"
                    class="d-flex align-center"
                  >
                    <v-icon
                      size="20"
                    >
                      {{ icons.mdiMapMarkerOutline }}
                    </v-icon>
                    <div
                      class="ms-2"
                    >
                      <p class="text--primary mb-0">
                        {{ `${event.city}, ${event.country}` }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-center mt-2"
                  >
                    <v-icon
                      size="20"
                    >
                      {{ icons.mdiClockOutline }}
                    </v-icon>
                    <div class="ms-2">
                      <p class="text--primary mb-0">
                        <span v-if="$moment(event.dateFrom).locale($i18n.locale).format('M') === $moment(event.dateTo).locale($i18n.locale).format('M')">
                          {{ $moment(event.dateFrom).locale($i18n.locale).format('D') }}
                          -
                          {{ $moment(event.dateTo).locale($i18n.locale).format('D MMMM, YYYY') }}
                        </span>
                        <span v-else>
                          {{ $moment(event.dateFrom).locale($i18n.locale).format('ddd, D MMMM') }}
                          -
                          {{ $moment(event.dateTo).locale($i18n.locale).format('ddd, D MMMM') }}
                        </span>
                      </p>
                    <!--<span class="text-xs">{{ data.subtitle }}</span>-->
                    </div>
                  </div>

                  <div
                    v-if="event.pabellon || event.stand"
                    class="d-flex align-center mt-2"
                  >
                    <v-icon
                      size="20"
                    >
                      mdi-location-enter
                    </v-icon>
                    <div class="ms-2">
                      <p class="text--primary mb-0">
                        <template v-if="event.pabellon">
                          {{ $t('landing.eventoPabellon') }} {{ event.pabellon }}
                        </template>
                        <span v-if="event.pabellon && event.stand">-</span>
                        <template v-if="event.stand">
                          {{ $t('landing.eventoStand') }} {{ event.stand }}
                        </template>
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <template v-if="existeQR">
                  <v-card-text>
                    <v-divider></v-divider>
                  </v-card-text>
                  <v-card-text class="text-center">
                    <v-row>
                      <v-col cols="2"></v-col>
                      <v-col cols="8">
                        <span v-html="qr"></span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text>
                    <v-divider></v-divider>
                  </v-card-text>
                  <v-card-text class="text-center">
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          color="primary"
                          block
                          outlined
                          :loading="isDownling"
                          @click="download"
                        >
                          <span>{{ $t('landing.eventSoli.downloadCata') }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </template>
              </v-card>
            </v-col>
          </v-row>

          <template v-if="!event.vencido">
            <EventPageImgs
              v-if="event.imgs.length > 0 || event.socios.length > 0"
              :event="event"
            />
          </template>
        </div>
      </template>

      <Loading
        v-if="isLoading"
        :full-page="true"
        :color="$vuetify.theme.themes.light.primary"
      />
    </section>
  </v-container>
</template>

<!--<script src="https://www.google.com/recaptcha/enterprise.js?render=6LfdBdcqAAAAABCGNeINxSscBF1A9w8lzQY2GZE-"></script>-->

<script>
// eslint-disable-next-line no-unused-vars
import { loadScript } from 'vue-plugin-load-script'
/* eslint-disable implicit-arrow-linebreak */
import { mdiMagnify, mdiClockOutline, mdiMapMarkerOutline } from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

import VueRecaptcha from 'vue-recaptcha'
import EventPageImgs from './EventPageImgs.vue'

export default {
  components: {
    Loading,
    EventPageImgs,
    VueRecaptcha,
  },
  data() {
    return {
      sitekey: null,
      recaptchaResponse: '',
      recaptchaToken: null,
      isLoading: true,
      isDownling: false,
      loading: false,
      loadingOK: false,
      messageSend: false,
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      name_project: process.env.VUE_APP_PROJ_NAME,
      icons: { mdiMagnify, mdiClockOutline, mdiMapMarkerOutline },
      event: {
        dateFrom: '',
      },
      qr: null,
      existeQR: false,
      imgs: [],
      img: null,
      imgsForm: [],
      imgForm: null,
      model: {
        code_country: '',
      },
      dayFree: [],
      datesFree: [],
      times: [],
      menuFrom: false,
      textRequiered: [v => !!v || this.$t('landing.rule1')],
      emailRules: [v => !!v || this.$t('landing.rule1'), v => this.isEmail(v) || this.$t('landing.rule2')],
      urlRules: [v => this.isURL(v) || this.$t('landing.rule3')],
      phoneRules: [v => !!v || this.$t('landing.rule1'), v => Number.isInteger(Number(v)) || this.$t('landing.rule4')],
      imgBackground: null,
    }
  },
  computed: {
    computedFromDateFormatted() {
      return this.model.date ? this.$moment(this.model.date).format('D-MMM-YY').locale(this.$i18n.locale) : ''
    },
  },

  mounted() {
    // loadScript('https://www.google.com/recaptcha/enterprise.js?render=6LfdBdcqAAAAABCGNeINxSscBF1A9w8lzQY2GZE-')
    // document.title = `Evento ${this.$route.params.slug}` // this.$t('landing.evento')
  },
  created() {
    this.fetchDataFromEndpoints()
    this.getEvent()
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event4.svg'))

    // eslint-disable-next-line import/no-unresolved
    this.imgsForm.push(require('@/assets/img/eventsFront/event1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgsForm.push(require('@/assets/img/eventsFront/event2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgsForm.push(require('@/assets/img/eventsFront/event3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgsForm.push(require('@/assets/img/eventsFront/event4.svg'))
    this.shuffleDeckForm()
  },
  methods: {
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    isURL(str) {
      let url

      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        url = new URL(str)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    isEmail(str) {
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        if (res.test(str)) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    autocompletarWeb() {
      if (this.model.site_web) {
        if (this.model.site_web.length === 1) {
          this.model.site_web = `https://${this.model.site_web}`
        }
      }
    },
    async fetchDataFromEndpoints() {
      try {
        const [
          resCountry,
          resApi,
        ] = await Promise.all([
          this.axios.get('https://ipapi.co/json'),
          this.axios.post('api-extern/show-front', { slug: 'recaptcha' }),
        ])

        this.model.code_country = resCountry.data.country_calling_code
        if (resApi.data.success) {
          this.sitekey = resApi.data.data.data.key
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    getCodeCountry() {
      this.axios
        .get('https://ipapi.co/json')
        .then(res => {
          this.model.code_country = res.data.country_calling_code
        })
    },
    async getEvent() {
      await this.axios
        .post('catalogs/show-event-front', { slug: this.$route.params.slug })
        .then(res => {
          if (res.data.success === false) {
            this.$router.push({ name: 'error-404' })
          } else {
            this.event = res.data.data.item

            document.title = this.getTitle(this.event)

            if (this.event.pdf !== '' && this.event.pdf !== null) {
              this.existeQR = true
            }

            this.dayFree = this.event.daysFree
            this.dayFree.forEach(element => {
              this.datesFree.push(element.date)
            })

            if (!this.event.banner_desktop) {
              // eslint-disable-next-line import/no-unresolved
              this.imgBackground = require('@/assets/images/misc/knowledge-base-bg-light.png')
            } else {
              this.imgBackground = this.urlS3 + this.event.banner_desktop
            }

            // ASIGNAR POR DEFECTO FECHA
            // this.model.date = this.$moment(this.event.dateFrom).format('Y-m-d')
            this.isLoading = false
          }
        })
        .finally(() => {
          this.shuffleDeck()
          if (this.existeQR) {
            this.getQR()
          }
        })
    },
    setDate() {
      this.model.time = null
      if (this.dayFree.filter(e => e.date === this.model.date).length > 0) {
        this.times = this.dayFree.filter(e => e.date === this.model.date)[0].times
      } else {
        this.times = []
      }
    },
    getQR() {
      this.axios
        .post('catalogs/qr-event', { slug: this.$route.params.slug })
        .then(res => {
          this.qr = res.data
        })
    },
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      if (this.event.logo) {
        this.img = this.urlS3 + this.event.logo
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.img = this.imgs[0]
      }
    },
    shuffleDeckForm() {
      const numbers = [...this.imgsForm]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgsForm = numbers
      // eslint-disable-next-line prefer-destructuring
      this.imgForm = this.imgsForm[0]
    },
    onVerify(response) {
      this.recaptchaResponse = response

      // Now you can send it to your Laravel backend
      this.send()
    },
    onExpired() {
      this.recaptchaResponse = ''
    },
    async onSubmit() {
      // await this.$refs.recaptcha.execute()

      /* console.log(this.recaptchaResponse)
      if (!this.recaptchaResponse) {
        this.$toast.error('Please complete the reCAPTCHA')
      } else {
        this.send()
      } */

      // const recaptchaValue = await this.$refs.recaptcha.execute()
      // console.log(recaptchaValue)

      // Send to your server for verification
      // this.send()
    },
    async send() {
      if (this.model.name
        && this.model.email
        && this.model.company
        && this.model.rol
        && this.model.date
        && this.model.time
        && this.model.code_country
        && this.model.phone
      ) {
        this.loading = true

        this.model.slug = this.$route.params.slug
        this.model.leng = this.$i18n.locale
        this.model.recaptcha = this.recaptchaResponse
        await this.axios
          .post('catalogs/solicitar-cita-event', this.model)
          .then(res => {
            if (res.data.success) {
              // this.$toast.success(this.$t('landing.event.h1'))
              this.loadingOK = true
              this.messageSend = true
              setTimeout(() => {
                this.messageSend = false
              }, 180000)
              this.model = {}

              // this.$router.push({ name: 'events' })
            } else if (res.data.data.status === 404) {
              const arr = []
              this.times.forEach(element => {
                if (element !== this.model.time) {
                  arr.push(element)
                }
              })
              this.model.time = null
              this.times = arr
              this.$toast.error(this.$t('landing.event.h2'))
            } else {
              this.$toast.error(res.data.message)
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    async download() {
      this.isDownling = true
      await this.axios
        .post('catalogs/qr-download-event', { id: this.event.id })
        .then(res => {
          this.downloadAPK(res.data.data.url)
        })
    },
    async downloadAPK(fileUrl) {
      try {
        // Realiza la solicitud para obtener el PDF
        const response = await fetch(fileUrl)

        // Verifica si la respuesta es correcta
        if (!response.ok) {
          throw new Error('Error al descargar el PDF')
        }

        // Crea un Blob a partir de la respuesta
        const blob = await response.blob()

        // Crea una URL para el Blob
        const url = window.URL.createObjectURL(blob)

        // Crea un elemento <a> y simula un clic en él
        const a = document.createElement('a')
        a.href = url
        a.download = `${this.event.title}.pdf` // Nombre que tendrá el archivo al descargarse
        document.body.appendChild(a)
        a.click()

        // Limpia
        a.remove()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('Error al descargar el archivo:', error)
      } finally {
        this.isDownling = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/knowledge-base.scss';
</style>
