<!-- eslint-disable vue/no-v-html -->
<template>
  <v-row>
    <v-col
      v-if="event.imgs.length > 0"
      cols="12"
      :md="event.socios.length > 0 ? '8' : '12'"
      sm="12"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="text-center"
            >
              <h3>{{ $t('landing.event.imgs') }}</h3>
            </v-col>
            <v-carousel
              cycle
              height="auto"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(itemImg, i) in event.imgs"
                :key="i"
              >
                <v-sheet
                  height="100%"
                >
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                  >
                    <v-img
                      class="cursor-pointer"
                      height="250px"
                      contain
                      :src="urlS3 + itemImg"
                      @click="showFullImg(itemImg)"
                    ></v-img>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>

            <!--<v-col
              v-for="(itemImg, indImg) in event.imgs"
              :key="indImg"
              cols="12"
              :md="event.socios.length > 0 ? '6' : '4'"
              sm="12"
            >
              <v-img
                class="cursor-pointer"
                height="250px"
                contain
                :src="urlS3 + itemImg"
                @click="showFullImg(itemImg)"
              ></v-img>
            </v-col>-->
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      v-if="event.socios.length > 0"
      cols="12"
      :md="event.imgs.length > 0 ? '4' : '12'"
      sm="12"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="text-center"
            >
              <h3>{{ $t('landing.event.socios') }}</h3>
            </v-col>
            <template v-for="(socio, indSoc) in event.socios">
              <v-col
                v-if="socios.filter(e => e.id === socio).length > 0 && socios.filter(e => e.id === socio)[0].id === socio"
                :key="indSoc"
                cols="12"
                :md="event.imgs.length > 0 ? '4' : '4'"
                :sm="event.imgs.length > 0 ? '6' : '4'"
              >
                <v-tooltip
                  color="primary"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      class="cursor-pointer"
                      max-height="100px"
                      :src="urlS3 + socios.filter(e => e.id === socio)[0].logo"
                      contain
                      v-bind="attrs"
                      v-on="on"
                    ></v-img>
                  </template>
                  <span>
                    {{ socios.filter(e => e.id === socio)[0].title }}
                  </span>
                </v-tooltip>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog
      v-model="showImg"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="white"
        >
          <v-btn
            icon
            dark
            @click="showImg = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-img
            max-height="640px"
            :src="urlS3 + imgEdit"
            contain
          ></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
import { mdiMagnify, mdiClockOutline, mdiMapMarkerOutline } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    event: {
      type: Object,
    },
  },
  data() {
    return {
      socios: [],
      showImg: false,
      imgEdit: null,
      isLoading: true,
      isDownling: false,
      loading: false,
      loadingOK: false,
      messageSend: false,
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      name_project: process.env.VUE_APP_PROJ_NAME,
      icons: { mdiMagnify, mdiClockOutline, mdiMapMarkerOutline },
    }
  },
  created() {
    this.fetchDataFromEndpoints()
  },
  methods: {
    async fetchDataFromEndpoints() {
      try {
        const [
          resSocios,
        ] = await Promise.all([
          this.axios
            .post('socios/list-front', { per_page: 1000000 }),
        ])

        // eslint-disable-next-line no-empty
        if (resSocios.data.success === false) { } else {
          this.socios = resSocios.data.data
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    showFullImg(imag) {
      this.imgEdit = imag
      this.showImg = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/knowledge-base.scss';
</style>
