<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <MenuSuperior />
      <v-container
        id="events"
        grid-list-xs
        class="px-14"
      >
        <v-row class="pt-10">
          <v-col cols="12">
            <EventPageShowDesktop />
          </v-col>
        </v-row>
      </v-container>

      <v-container
        grid-list-xs
        class="px-15 mt-16 darkBlueMine mx-0"
        style="min-width:100%"
      >
        <v-row class="">
          <v-col cols="12">
            <Secfooter />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- movil -->
    <div class="d-md-none">
      <v-app-bar
        fixed
        color="#f4f5fa"
        elevation="1"
        class="pt-0"
      >
        <div
          style="width: 105px; height:60px"
          class="pt-2"
        >
          <v-img
            :lazy-src="`${urlS3}wamasolplus-03.png`"
            :src="`${urlS3}wamasolplus-03.png`"
            @click="$router.push({ name: 'home' })"
          >
          </v-img>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mx-4"
          @click.stop="drawer = !drawer"
        >
          <v-icon>{{ icons.mdiMenu }}</v-icon>
        </v-btn>
      </v-app-bar>

      <EventPageShowMobile />
      <v-container
        grid-list-xs
        class="darkBlueMine mx-0"
      >
        <v-row class="">
          <v-col cols="12">
            <Secfooter />
          </v-col>
        </v-row>
      </v-container>

      <MenuSuperiorMovil
        :drawer="drawer"
        @setDrawler="setDrawler"
      />
    </div>

    <!-- CHAT --
    <Chat /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  mdiPlus, mdiChevronDown, mdiChevronRight, mdiMenu,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import MenuSuperior from '@/views/home/utils/menuSuperior.vue'
// eslint-disable-next-line import/no-unresolved
import MenuSuperiorMovil from '@/views/home/utils/menuSuperiorMovil.vue'
import Secfooter from '../utils/Secfooter.vue'
import EventPageShowDesktop from './EventPageShowDesktop.vue'
import EventPageShowMobile from './EventPageShowMobile.vue'

import Chat from '../../chat/front/Index.vue'

export default {
  components: {
    MenuSuperior,
    MenuSuperiorMovil,
    Secfooter,
    // eslint-disable-next-line vue/no-unused-components
    Chat,
    EventPageShowDesktop,
    EventPageShowMobile,
  },
  data() {
    return {
      urlS3: process.env.VUE_APP_URL_S3,
      drawer: false,
      idioma: false,
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiChevronRight,
        mdiMenu,
      },
    }
  },
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
    availableLocales() {
      return this.languages.filter(i => i.locale !== this.$i18n.locale)
    },
  },
  methods: {
    setDrawler() {
      this.drawer = !this.drawer
    },
  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth !important;
}

.lightBackBlueMine {
  background-color: #0198c7;
}

.lightBackBlueV2Mine {
  background-color: #0199c797;
}

.darkBlueMine {
  background-color: #1a5666;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

a {
  text-decoration: none;
}

a > .menuSupLinkMine {
  color: black;
  font-family: OpenSansRegular;
}
</style>
